import * as React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import ErrorText from './ErrorText';

export default function FormError({ meta = {}, ...props }) {
  if ((meta.touched || meta.submitFailed) && (meta.error || meta.submitError)) {
    return (
      <ErrorText {...props}>
        {meta.error ||
          (isArray(meta.submitError)
            ? get(meta, 'submitError[0]')
            : meta.submitError)}
      </ErrorText>
    );
  }

  return null;
}

FormError.propTypes = { meta: PropTypes.object.isRequired };
