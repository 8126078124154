import React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../utils';

export default function Switch({
  label,
  color,
  className,
  labelClassName,
  toggleClassName,
  wrapperClassName,
  ...props
}) {
  const hasLabel = label ? 'mr-2' : '';
  const disabledLabel = props.disabled
    ? 'text-custom-gray-200 cursor-not-allowed'
    : 'text-custom-black';

  return (
    <label
      htmlFor={props.id}
      className={
        wrapperClassName
          ? joinClassNames(
              `inline-flex relative items-center cursor-pointer form-check-label select-none ${disabledLabel}`,
              wrapperClassName,
            )
          : `inline-flex relative items-center cursor-pointer form-check-label select-none ${disabledLabel}`
      }
    >
      <input
        className={
          className
            ? joinClassNames(`sr-only peer pointer-events-none`, className)
            : `sr-only peer pointer-events-none`
        }
        type="checkbox"
        role="switch"
        {...props}
      />
      <div
        className={
          toggleClassName
            ? joinClassNames(
                `w-11 h-5 bg-gray-200 rounded-full peer peer-checked:bg-custom-blue-solid peer-focus:ring-2 peer-focus:ring-offset-2 peer-focus:ring-custom-blue-solid dark:bg-custom-gray-300 peer-checked:after:translate-x-[150%] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-custom-gray-300 peer-disabled:bg-custom-gray-200 pointer-events-none ${hasLabel}`,
                toggleClassName,
              )
            : `w-11 h-5 bg-gray-200 rounded-full peer peer-checked:bg-custom-blue-solid peer-focus:ring-2 peer-focus:ring-offset-2 peer-focus:ring-custom-blue-solid dark:bg-custom-gray-300 peer-checked:after:translate-x-[150%] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-custom-gray-300 peer-disabled:bg-custom-gray-200 pointer-events-none ${hasLabel}`
        }
      />
      {label && (
        <span
          className={
            labelClassName
              ? joinClassNames('-mt-1 relative -top-0.5', labelClassName)
              : '-mt-1 relative top-0.5'
          }
        >
          {label}
        </span>
      )}
    </label>
  );
}

Switch.defaultProps = {
  label: undefined,
  color: undefined,
  className: undefined,
  labelClassName: undefined,
  toggleClassName: undefined,
  wrapperClassName: undefined,
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  /**
   * TailwindCSS color.
   */
  color: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  toggleClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
