import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../../../components/Switch/Switch';

const PermissionsComponent = ({
  permissionOptions,
  permissions,
  changePermissions,
}) => {
  const onChange = key => event => changePermissions(key, event.target.checked);
  return permissionOptions?.map(
    ({ id, name, trueLabel, falseLabel, description }, index) => {
      const isLast = index === permissionOptions.length - 1;
      const permissionKey = id === 1 ? 'active' : 'accessible';
      return (
        <div
          key={id}
          className={`flex pt-[30px] pb-[25px] px-[34px] rounded-lg border border-custom-gray-200 ${
            !isLast ? 'mb-2.5' : ''
          }`}
        >
          <div className="flex flex-col justify-center items-start text-black">
            <div className="w-full grid grid-cols-1 sm:grid-cols-6 mb-3 gap-5 sm:gap-0">
              <p className="col-span-1 sm:col-span-4 font-semibold">{name}</p>
              <Switch
                id={`switch-${id}`}
                label={
                  Object.values(permissions)[id - 1] ? trueLabel : falseLabel
                }
                checked={Object.values(permissions)[id - 1]}
                onChange={onChange(permissionKey)}
                toggleClassName="after:top-[2px] sm:after:top-[4px] peer-checked:bg-custom-green-solid"
                wrapperClassName="col-span-2"
              />
            </div>
            <span className="mt-2 text-left text-[15px]">{description}</span>
          </div>
        </div>
      );
    },
  );
};

export default PermissionsComponent;

PermissionsComponent.defaultProps = {
  permissionOptions: undefined,
  permissions: undefined,
  changePermissions: undefined,
};
PermissionsComponent.propTypes = {
  permissionOptions: PropTypes.array,
  permissions: PropTypes.object,
  changePermissions: PropTypes.func,
};
