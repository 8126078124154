import React from 'react';
import PropTypes from 'prop-types';

const StepComponent = ({ steps, activeStep }) =>
  steps?.map(({ number, name }, index) => {
    const isCompletedStep = index < activeStep - 1;
    const isActiveStep = number === activeStep;
    const isLastStep = index === 2;

    const isCompletedNumber = isCompletedStep
      ? 'bg-custom-primary-400 text-white'
      : 'bg-custom-gray-100 text-custom-gray-200';
    const isCompletedName = isCompletedStep
      ? 'text-custom-primary-400'
      : 'text-custom-gray-300';

    return (
      <React.Fragment key={number}>
        <div
          className={`flex flex-col sm:flex-row ${
            index !== steps.length - 1 ? 'mr-5' : ''
          } sm:mr-0 items-center justify-center`}
        >
          <span
            className={`w-10 h-10 flex justify-center items-center rounded-full font-semibold ${
              isActiveStep
                ? 'bg-white text-custom-primary-400 border border-custom-primary-400'
                : isCompletedNumber
            }`}
          >
            {number}
          </span>
          <span
            className={`sm:ml-3 font-semibold ${
              isActiveStep ? 'text-custom-black' : isCompletedName
            }`}
          >
            {name}
          </span>
        </div>
        {!isLastStep && (
          <span className="w-[35px] h-0.5 ml-3 mr-6 bg-custom-gray-200 hidden sm:block" />
        )}
      </React.Fragment>
    );
  });

export default StepComponent;

StepComponent.defaultProps = {
  steps: undefined,
  activeStep: undefined,
  navigate: undefined,
};
StepComponent.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  navigate: PropTypes.func,
};
