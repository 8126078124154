import React from 'react';
import PropTypes from 'prop-types';
import TeamStep from './TeamsStep/TeamStep';
import PermissionsStep from './PermissionsStep/PermissionsStep';
import DetailsStep from './DetailsStep/DetailsStep';

export default function Step({
  steps,
  currentStep,
  navigate,
  onClose,
  team,
  changeTeam,
  permissions,
  changePermissions,
  triggerScrollToBottom,
}) {
  switch (currentStep) {
    case 1:
      return (
        <TeamStep
          steps={steps}
          navigate={navigate}
          onClose={onClose}
          team={team}
          changeTeam={changeTeam}
        />
      );
    case 2:
      return (
        <PermissionsStep
          steps={steps}
          navigate={navigate}
          onClose={onClose}
          team={team}
          permissions={permissions}
          changePermissions={changePermissions}
        />
      );
    case 3:
      return (
        <DetailsStep
          steps={steps}
          navigate={navigate}
          onClose={onClose}
          team={team}
          permissions={permissions}
          triggerScrollToBottom={triggerScrollToBottom}
        />
      );
    default:
      return (
        <TeamStep
          steps={steps}
          onClose={onClose}
          navigate={navigate}
          team={team}
          changeTeam={changeTeam}
        />
      );
  }
}

Step.defaultProps = {
  steps: undefined,
  currentStep: undefined,
  navigate: undefined,
  onClose: undefined,
  team: undefined,
  changeTeam: undefined,
  permissions: undefined,
  changePermissions: undefined,
  triggerScrollToBottom: undefined,
};
Step.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  navigate: PropTypes.func,
  onClose: PropTypes.func,
  team: PropTypes.number,
  changeTeam: PropTypes.func,
  permissions: PropTypes.object,
  changePermissions: PropTypes.func,
  triggerScrollToBottom: PropTypes.func,
};
