import React from 'react';
import { components } from 'react-select';
import Icon from '../../../components/Icon/Icon';

export default function SingleValueOption(props) {
  const { data, clearValue, isDisabled } = props || {};
  const onKeyDown = event => (event.key === 'Enter' ? clearValue() : null);

  return (
    <div
      tabIndex="-1"
      role="button"
      className="absolute"
      onMouseDown={e => e.stopPropagation()}
    >
      <components.SingleValue {...props}>
        <div
          className={`max-w-full inline-block border border-custom-gray-200 rounded-lg ${
            isDisabled
              ? 'bg-custom-gray-200 cursor-not-allowed'
              : 'bg-custom-gray-100 cursor-default'
          }`}
        >
          <div className="flex items-center py-0.5 px-2">
            <p
              className={`mr-1.5 font-medium ${
                isDisabled ? 'text-custom-gray-300' : 'text-custom-black'
              } text-[12px] whitespace-nowrap overflow-hidden text-ellipsis uppercase`}
            >
              {data.label}
            </p>
            <button
              type="button"
              className="flex justify-center items-center"
              onKeyDown={onKeyDown}
              onMouseUp={clearValue}
              onTouchEnd={clearValue}
            >
              <Icon
                name="close"
                className={`w-3.5 h-3.5 ${
                  isDisabled ? 'text-custom-gray-300' : 'text-custom-black'
                }`}
              />
            </button>
          </div>
        </div>
      </components.SingleValue>
    </div>
  );
}
