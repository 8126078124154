import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../components/Image/Image';
import UserCreatedButtons from './UserCreatedButtons';

export default function UserCreatedScreen({ user, permissions, onClose }) {
  const paragraphText =
    (!permissions?.active && permissions?.accessible) ||
    (permissions?.active && permissions?.accessible)
      ? `We have sent an email to ${user?.email ??
          'email@example.com'} with access to the account.`
      : 'The user has been created, but does not have access to edit it. Keep it updated to get the most out of the platform.';

  return (
    <>
      <p className="font-poppins font-semibold text-center text-[26px] leading-[39px]">
        User Created
      </p>
      <div className="mt-[30px] flex flex-col items-center justify-center">
        <Image
          width="248"
          height="170"
          alt="User created high five"
          className="h-[200px] object-contain"
          src="https://res.cloudinary.com/drrsqepgv/image/upload/v1666776941/The__A__Team_Recipie_2_on0yve.png"
        />
        <p className="mt-10 text-center">{paragraphText}</p>
      </div>
      <UserCreatedButtons
        user={user}
        permissions={permissions}
        onClose={onClose}
      />
    </>
  );
}

UserCreatedScreen.defaultProps = {
  user: undefined,
  onClose: undefined,
  permissions: undefined,
};

UserCreatedScreen.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  permissions: PropTypes.object,
};
