import * as React from 'react';
import { components } from 'react-select';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import Icon from '../../../components/Icon/Icon';

export default function SingleValueCompany(props) {
  const { data, clearValue, isDisabled } = props || {};
  const onKeyDown = event => (event.key === 'Enter' ? clearValue() : null);
  const isDisabledLogoBackgroundColor = isDisabled
    ? 'bg-custom-gray-300'
    : 'bg-custom-blue-solid';

  return (
    <div
      tabIndex="-1"
      role="button"
      className="absolute"
      onMouseDown={e => e.stopPropagation()}
    >
      <components.SingleValue {...props}>
        <div
          className={`max-w-full inline-block border-custom-gray-200 border rounded-lg ${
            isDisabled
              ? 'bg-custom-gray-200 cursor-not-allowed'
              : 'bg-custom-gray-100 cursor-default'
          }`}
        >
          <div className="flex items-center pr-2">
            <div
              style={{
                backgroundImage: !isNil(data?.logo) ? `url(${data?.logo})` : '',
              }}
              className={`w-[22px] h-[22px] rounded-tl-lg rounded-bl-lg border-r border-custom-gray-200  bg-no-repeat bg-center bg-cover flex justify-center items-center text-white font-semibold font-poppins ${
                !isNil(data?.logo)
                  ? 'transparent'
                  : isDisabledLogoBackgroundColor
              }`}
            >
              {!isNil(data?.logo) ? '' : get(data, 'label', 'Deazy')?.charAt(0)}
            </div>
            <p
              className={`mx-1.5 font-medium ${
                isDisabled ? 'text-custom-gray-300' : 'text-custom-black'
              } text-[12px] whitespace-nowrap overflow-hidden text-ellipsis`}
            >
              {data.label}
            </p>
            <button
              type="button"
              className="flex justify-center items-center"
              onKeyDown={onKeyDown}
              onMouseUp={clearValue}
              onTouchEnd={clearValue}
            >
              <Icon
                name="close"
                className={`w-3.5 h-3.5 ${
                  isDisabled ? 'text-custom-gray-300' : 'text-custom-black'
                }`}
              />
            </button>
          </div>
        </div>
      </components.SingleValue>
    </div>
  );
}
