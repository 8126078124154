import axios from 'axios';
import Cookies from 'js-cookie';
import { trimObj } from '../utils';
import apiMock from './apiMock';

export const TOKEN_KEY = 'deazy-auth-token';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers.common = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

const removeAuthorization = () => {
  Cookies.remove(TOKEN_KEY);
  delete api.defaults.headers.common.Authorization;
};

apiMock(api);

api.interceptors.request.use(
  config => {
    if (config.data) {
      return {
        ...config,
        data: trimObj(config.data),
      };
    }
    return config;
  },
  error => Promise.reject(error),
);

const API = {
  getRoles: () => api.get('/role'),
  getTimezones: () => api.get('/settings/timezones'),
  getSuppliers: () => api.get('/supplier'),
  getCustomers: () => api.get('/customer'),
  getSupplierByRole: () => api.get('/supplier/me'),
  getCustomerByRole: () => api.get('/customer/me'),
  createUser: data => api.post('/user', data),
  apiInstance: api,
};

export { API, removeAuthorization };
