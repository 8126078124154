import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FormError from '../Error/FormError';
import { joinClassNames } from '../../utils';

export default function Input({
  id,
  label,
  meta,
  type,
  className,
  labelClassName,
  ...props
}) {
  const errorBorderColor =
    meta?.submitError && meta?.submitFailed
      ? 'border-custom-red-solid'
      : 'border-custom-gray-250';

  return (
    <>
      {!isEmpty(label) && (
        <label
          htmlFor={id}
          className={
            labelClassName
              ? joinClassNames(
                  'inline-block mb-[5px] leading-[19px] font-semibold text-custom-primary-600',
                  labelClassName,
                )
              : 'inline-block mb-[5px] leading-[19px] font-semibold text-custom-primary-600'
          }
        >
          {label}
        </label>
      )}
      <input
        className={
          className
            ? joinClassNames(
                `max-h-[46px] appearance-none border ${errorBorderColor} outline-none rounded-[5px] w-full py-3.5 px-2.5 text-custom-primary-600 leading-[18px] focus:outline-none focus:ring-2 focus:ring-custom-blue-solid placeholder:text-custom-gray-200 selection:bg-custom-primary-300 disabled:bg-custom-gray-100 disabled:text-custom-gray-300`,
                className,
              )
            : `max-h-[46px] appearance-none border ${errorBorderColor} outline-none rounded-[5px] w-full py-3.5 px-2.5 text-custom-primary-600 leading-[18px] focus:outline-none focus:ring-2 focus:ring-custom-blue-solid placeholder:text-custom-gray-200 selection:bg-custom-primary-300 disabled:bg-custom-gray-100 disabled:text-custom-gray-300`
        }
        type={type || 'text'}
        {...props}
      />
      {!isEmpty(meta) && <FormError absolute="true" meta={meta} />}
    </>
  );
}

Input.defaultProps = {
  type: 'text',
  placeholder: undefined,
  className: undefined,
  labelClassName: undefined,
  meta: undefined,
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.object,
};
