import React from 'react';
import { runInAction, makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import isArray from 'lodash/isArray';

export default class UserStore {
  constructor({ API }) {
    makeAutoObservable(this);
    this.API = API;
  }

  user = {};

  isLoading = false;

  createUser = postData => {
    runInAction(async () => {
      try {
        this.user = {};
        this.isLoading = true;
        const { data } = await this.API.createUser(postData);
        this.user = data;
      } catch (e) {
        const statusCode = e?.response?.status;
        const statusText = e?.response?.statusText;
        const error = e?.response?.data?.error;
        const errorMessage = e?.response?.data?.message;
        const messageUppercased = isArray(errorMessage)
          ? `${errorMessage?.[0]
              ?.charAt(0)
              ?.toUpperCase()}${errorMessage?.[0]?.slice(1)}`
          : `${errorMessage?.charAt(0)?.toUpperCase()}${errorMessage?.slice(
              1,
            )}`;

        toast.error(
          statusCode ? (
            <>
              <b>{`${statusCode}:`}&nbsp;</b>
              {`${error || statusText}`}
              <span>&nbsp;-&nbsp;</span>
              {errorMessage ? <span>{messageUppercased}</span> : null}
            </>
          ) : (
            e?.message
          ),
        );
      } finally {
        this.isLoading = false;
      }
    });
  };

  roles = [];

  isLoadingRoles = false;

  getRoles = () => {
    runInAction(async () => {
      try {
        this.roles = [];
        this.isLoadingRoles = true;
        const { data } = await this.API.getRoles();
        this.roles = data;
      } catch (e) {
        toast.error('Network error');
      } finally {
        this.isLoadingRoles = false;
      }
    });
  };

  timezones = [];

  isLoadingTimezones = false;

  getTimezones = () => {
    runInAction(async () => {
      try {
        this.timezones = [];
        this.isLoadingTimezones = true;
        const { data } = await this.API.getTimezones();
        this.timezones = data;
      } catch (e) {
        toast.error('Network error');
      } finally {
        this.isLoadingTimezones = false;
      }
    });
  };
}
