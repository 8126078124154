import React from 'react';
import { useAuth } from '@frontegg/react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import StepComponent from '../StepComponent';
import PermissionsComponent from './PermissionsComponent';
import Solid from '../../../components/Button/Solid/Solid';
import Outlined from '../../../components/Button/Outlined/Outlined';

export default function PermissionsStep({
  steps,
  navigate,
  onClose,
  team,
  permissions,
  changePermissions,
}) {
  const { user } = useAuth();
  const authenticatedUserRoles = user?.roles?.map(role => role?.name);
  const isClientLead = authenticatedUserRoles?.includes('Client Lead');

  const permissionOptions = [
    !(
      ((team === 0 && isClientLead) || team === 2) &&
      permissions.active === false
    ) && {
      id: 1,
      name: 'Profile Activation',
      trueLabel: 'Active',
      falseLabel: 'Inactive',
      description:
        'The user will have an active profile to show skills, avaliability, lorem ipsum dolor sit amet.',
    },
    {
      id: 2,
      name: 'Account Access',
      trueLabel: 'Has access',
      falseLabel: 'No access',
      description:
        'The user will have its own access to edit all its details lorem ipsum dolor sit amet.',
    },
  ]?.filter(permission => permission);

  return (
    <>
      <p className="px-[38px] font-poppins font-semibold text-center text-[26px] leading-[39px]">
        Select Permisions
      </p>
      {!isEmpty(steps) && (
        <div className="my-[30px] flex flex-row items-center justify-center flex-wrap sm:flex-nowrap">
          <StepComponent steps={steps} activeStep={2} navigate={navigate} />
        </div>
      )}
      <div
        className={`flex flex-col items-center justify-center ${
          isEmpty(steps) ? 'mt-[38px]' : ''
        }`}
      >
        <PermissionsComponent
          permissionOptions={permissionOptions}
          permissions={permissions}
          changePermissions={changePermissions}
        />
      </div>
      <div className="mt-[30px] flex flex-col">
        <Solid className="w-full" onClick={() => navigate(3)}>
          Next Step
        </Solid>
        <Outlined className="mt-2 w-full" onClick={onClose}>
          Cancel
        </Outlined>
      </div>
    </>
  );
}

PermissionsStep.defaultProps = {
  steps: undefined,
  navigate: undefined,
  onClose: undefined,
  team: undefined,
  permissions: undefined,
  changePermissions: undefined,
};

PermissionsStep.propTypes = {
  steps: PropTypes.array,
  navigate: PropTypes.func,
  onClose: PropTypes.func,
  team: PropTypes.number,
  permissions: PropTypes.object,
  changePermissions: PropTypes.func,
};
