import { runInAction, makeAutoObservable } from 'mobx';

export default class AppStore {
  constructor({ API }) {
    makeAutoObservable(this);
    this.API = API;
  }

  isLoading = false;

  toggleLoading = isLoading => {
    runInAction(() => {
      this.isLoading = isLoading;
    });
  };
}
