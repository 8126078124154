import React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../../utils';
import Button from '../Button';

const buttonColors = {
  primary:
    'bg-white rounded-lg px-6 py-2.5 border-2 border-custom-primary-400 font-work-sans leading-4 text-custom-primary-400 hover:bg-custom-primary-100 active:bg-custom-primary-300 disabled:bg-white disabled:text-custom-gray-200 disabled:border-custom-gray-200',
  secondary:
    'bg-white rounded-lg px-6 py-2.5 border-2 border-custom-orange-solid font-work-sans leading-4 text-custom-orange-solid hover:bg-custom-orange-light active:bg-custom-orange-mid disabled:bg-white disabled:text-custom-gray-200 disabled:border-custom-gray-200',
  warning:
    'bg-white rounded-lg px-6 py-2.5 border-2 border-custom-red-solid font-work-sans leading-4 text-custom-red-solid hover:bg-custom-red-light active:bg-custom-red-mid disabled:bg-white disabled:text-custom-gray-200 disabled:border-custom-gray-200',
};

/**
 * Outlined Button component
 * @param {String} className
 * @param {String} variant String of the color variant:
 * ```primary``` | ```secondary``` | ```warning```. Defaults to ```primary```.
 */
export default function Outlined({ className, variant = 'primary', ...props }) {
  const memoButtonColors = React.useMemo(() => buttonColors[variant], [
    variant,
  ]);

  return (
    <Button
      type="outlined"
      variant={variant}
      className={
        className
          ? joinClassNames(memoButtonColors, className)
          : memoButtonColors
      }
      {...props}
    />
  );
}

Outlined.defaultProps = {
  className: undefined,
  variant: 'primary',
  leftIcon: undefined,
  leftIconProps: undefined,
  leftIconClassName: undefined,
  iconButton: undefined,
  iconButtonProps: undefined,
  iconButtonClassName: undefined,
  children: undefined,
  disabled: undefined,
  type: 'outlined',
  isLoading: undefined,
  loadingText: undefined,
  loadingTextProps: undefined,
  loadingTextClassName: undefined,
};

Outlined.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  leftIconClassName: PropTypes.string,
  iconButton: PropTypes.string,
  iconButtonProps: PropTypes.object,
  iconButtonClassName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingTextProps: PropTypes.object,
  loadingTextClassName: PropTypes.string,
};
