import React, { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';

export default function NotFound() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1500);
    return () => clearTimeout(timeout);
  }, []);

  return isLoading ? (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <Spinner className="w-10 h-10" />
    </div>
  ) : (
    <div className="h-screen w-screen flex justify-center items-center">
      Page Not Found
    </div>
  );
}
