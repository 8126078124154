import React from 'react';
import ReactDOMClient from 'react-dom/client';

import 'element.scrollintoviewifneeded-polyfill';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.css';

import { Provider } from 'mobx-react';
import { configure } from 'mobx';

import { BrowserRouter } from 'react-router-dom';
import { FronteggProvider } from '@frontegg/react';
import history from './app/history';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootStore from './stores/rootStore';
import { fronteggTheme } from './app/constants';
import Toaster from './components/Toast/Toaster';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

configure({
  enforceActions: 'never',
});

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONTEGG_BASE_URL,
};

root.render(
  <Provider {...rootStore}>
    <BrowserRouter history={history}>
      <FronteggProvider
        history={history}
        hostedLoginBox={false}
        themeOptions={fronteggTheme}
        contextOptions={contextOptions}
      >
        <Toaster />
        <App />
      </FronteggProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
