import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { useAuth } from '@frontegg/react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import validator from 'validator';
import { API } from '../../../app/api';
import { customSelectStylesEditProfile } from '../../../app/constants';
import Input from '../../../components/Input/Input';
import Solid from '../../../components/Button/Solid/Solid';
import Outlined from '../../../components/Button/Outlined/Outlined';
import FormError from '../../../components/Error/FormError';
import SingleValueOption from './SingleValueOption';
import SingleValueCompany from './SingleValueCompany';
import LoadingIndicatorComponent from './LoadingIndicatorComponent';
import NoOptionsMessageComponent from './NoOptionsMessageComponent';

function DetailsComponent({
  onClose,
  triggerScrollToBottom,
  team,
  permissions,
  userStore: {
    isLoading,
    createUser,
    roles,
    isLoadingRoles,
    getRoles,
    timezones,
    isLoadingTimezones,
    getTimezones,
  },
}) {
  const { user } = useAuth();
  const authenticatedUserRoles = user?.roles?.map(role => role?.name);
  const isAdmin = authenticatedUserRoles?.includes('Admin');
  const isDeazyLead = authenticatedUserRoles?.includes('Deazy Lead');
  const isTeamAdminOnly =
    user?.roles?.length === 1 && authenticatedUserRoles?.includes('Team Admin');
  const isClientLeadOnly =
    user?.roles?.length === 1 &&
    authenticatedUserRoles?.includes('Client Lead');

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    getRoles();
    getTimezones();
    getSelectData();
  }, []);

  const loadOptions = (inputValue, callback) =>
    callback(filterDeliveryPartners(inputValue));

  const filterDeliveryPartners = inputValue => {
    return defaultOptions?.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const getSelectData = () => {
    if (team === 3) return;

    let getFunction;
    let isObject = false;

    if (team === 1) getFunction = API.getSuppliers;
    else if (team === 2) getFunction = API.getCustomers;
    else if (team === 0 && isTeamAdminOnly) {
      isObject = true;
      getFunction = API.getSupplierByRole;
    } else if (team === 0 && isClientLeadOnly) {
      isObject = true;
      getFunction = API.getCustomerByRole;
    }

    if (!defaultOptions.length) {
      setIsLoadingData(true);
      getFunction()
        .then(res => {
          const options = isObject
            ? [
                {
                  value: res?.data?.id,
                  label: res?.data?.name,
                  ...res?.data,
                },
              ]
            : res?.data?.map(data => ({
                value: data.id,
                label: data.name,
                data,
              }));
          setDefaultOptions(options);
          setIsLoadingData(false);
        })
        .catch(() => {
          setIsLoadingData(false);
          toast.error('Network error');
        });
    }
  };

  const onSubmit = async values => {
    const isTeamMember =
      (values?.role?.[0]?.value || values?.role?.value) === 'TM';

    const data = {
      hasProfile: values?.active ?? null,
      hasAccess: values?.accessible ?? null,
      ...(!(isTeamMember && !permissions?.accessible) && {
        email: values?.email ?? null,
      }),
      firstName: values?.firstName ?? null,
      lastName: values?.lastName ?? null,
      role: (values?.role?.[0]?.value || values?.role?.value) ?? null,
      timezone:
        (values?.timezone?.[0]?.value || values?.timezone?.value) ?? null,
      ...(!((isAdmin || isDeazyLead) && team === 3) && {
        company:
          (values?.company?.[0]?.value || values?.company?.value) ?? null,
      }),
    };

    const requiredFieldsArray = [
      { firstName: values?.firstName },
      { lastName: values?.lastName },
      !(isTeamMember && !permissions?.accessible) && {
        email: values?.email,
      },
      { role: values?.role },
      { timezone: values?.timezone },
      !((isAdmin || isDeazyLead) && team === 3) && { company: values?.company },
    ];

    const requiredFieldsArrayFormatted = requiredFieldsArray
      ?.filter(fieldObject => fieldObject)
      ?.map(fieldObject => ({
        key: Object.keys(fieldObject)[0],
        value: Object.values(fieldObject)[0],
      }));

    const errors = {};

    requiredFieldsArrayFormatted?.forEach(field => {
      if (isEmpty(field.value)) {
        errors[`${field.key}`] = 'This field is required';
      } else {
        const isInvalidEmail =
          field?.key === 'email' && !validator.isEmail(field.value);
        if (isInvalidEmail)
          errors[`${field.key}`] = 'Must be a valid email address';
      }
    });

    if (!isEmpty(errors)) return errors;

    createUser(data);
  };

  const roleOptions = roles?.map(role => ({
    ...role,
    value: role?.name,
    label: role?.description,
  }));

  const getRoleOptions = () => {
    let teamAdminClientLeadOnlyRoles = [];

    if (isTeamAdminOnly)
      teamAdminClientLeadOnlyRoles = roleOptions?.filter(
        role => role?.name === 'TA' || role?.name === 'TM',
      );
    else if (isClientLeadOnly)
      teamAdminClientLeadOnlyRoles = roleOptions?.filter(
        role => role?.name === 'CL',
      );

    switch (team) {
      case 0:
        return teamAdminClientLeadOnlyRoles;
      case 1:
        return roleOptions?.filter(
          role => role?.name === 'TA' || role?.name === 'TM',
        );
      case 2:
        return roleOptions?.filter(role => role?.name === 'CL');
      case 3:
        return roleOptions?.filter(
          role => role?.name === 'Admin' || role?.name === 'DL',
        );
      default:
        return roleOptions;
    }
  };

  const filteredRoleOptions = getRoleOptions();

  const timezoneOptions = timezones?.map(timezone => ({
    ...timezone,
    value: timezone?.id,
    label: `(${timezone?.gmtOffsetName}) ${timezone?.zoneName} (${timezone?.abbreviation})`,
  }));

  const isTeamAdminClientLeadOnly =
    team === 0 && (isTeamAdminOnly || isClientLeadOnly);

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      initialValues={{
        team: team ?? null,
        active: permissions?.active ?? null,
        accessible: permissions?.accessible ?? null,
        firstName: null,
        lastName: null,
        email: null,
        role: null,
        timezone: null,
        company: isTeamAdminClientLeadOnly ? defaultOptions?.[0] : null,
      }}
    >
      {({ values, handleSubmit }) => {
        const isTeamMember =
          (values?.role?.[0]?.value || values?.role?.value) === 'TM';

        return (
          <>
            <Field name="firstName">
              {({ input, meta }) => (
                <div className="w-full relative">
                  <div className="mb-10">
                    <Input
                      id="firstName-input"
                      disabled={isLoading}
                      label="First name"
                      placeholder="Type..."
                      meta={meta}
                      {...input}
                    />
                  </div>
                </div>
              )}
            </Field>
            <Field name="lastName">
              {({ input, meta }) => (
                <div className="w-full relative">
                  <div className="mb-10">
                    <Input
                      id="lastName-input"
                      disabled={isLoading}
                      label="Last name"
                      placeholder="Type..."
                      meta={meta}
                      {...input}
                    />
                  </div>
                </div>
              )}
            </Field>
            {!(isTeamMember && !permissions?.accessible) && (
              <Field name="email">
                {({ input, meta }) => (
                  <div className="w-full relative">
                    <div className="mb-10">
                      <Input
                        id="email-input"
                        disabled={isLoading}
                        label="Email"
                        placeholder="Type..."
                        type="email"
                        meta={meta}
                        {...input}
                      />
                    </div>
                  </div>
                )}
              </Field>
            )}
            <Field name="role">
              {({ input, meta }) => (
                <div className="w-full relative">
                  <div className="mb-10">
                    <label
                      htmlFor="role-input"
                      className="inline-block mb-[5px] leading-[19px] font-semibold text-custom-primary-600"
                    >
                      User role
                    </label>
                    <Select
                      id="role-input"
                      escapeClearsValue
                      options={filteredRoleOptions}
                      placeholder="Type/Select..."
                      classNamePrefix="react-select-prefix"
                      isLoading={isLoadingRoles}
                      isDisabled={isLoading || isLoadingRoles}
                      styles={{
                        ...customSelectStylesEditProfile,
                        control: (styles, state) => ({
                          ...styles,
                          maxHeight: '46px',
                          padding: '0 10px',
                          borderRadius: '5px',
                          background: state.isDisabled ? '#F9F9F9' : 'white',
                          border:
                            meta.submitError && meta.submitFailed
                              ? '1px solid #FF6666'
                              : '1px solid #C2BFBC',
                          boxShadow: state.isFocused
                            ? '0 0 0 3px #3366FF'
                            : 'none',
                          cursor: 'text',
                          '&:hover': {
                            borderColor: '#C2BFBC',
                          },
                        }),
                      }}
                      components={{
                        SingleValue: componentProps =>
                          SingleValueOption(componentProps),
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                        LoadingIndicator: () => LoadingIndicatorComponent(),
                        NoOptionsMessage: () => NoOptionsMessageComponent(),
                      }}
                      onMenuOpen={triggerScrollToBottom ?? noop}
                      {...input}
                    />
                    <FormError absolute="true" meta={meta} />
                  </div>
                </div>
              )}
            </Field>
            <Field name="timezone">
              {({ input, meta }) => (
                <div className="w-full relative">
                  <div className="mb-10">
                    <label
                      htmlFor="timezone-input"
                      className="inline-block mb-[5px] leading-[19px] font-semibold text-custom-primary-600"
                    >
                      Time Zone
                    </label>
                    <Select
                      id="timezone-input"
                      escapeClearsValue
                      options={timezoneOptions}
                      placeholder="Type/Select..."
                      classNamePrefix="react-select-prefix"
                      isLoading={isLoadingTimezones}
                      isDisabled={isLoading || isLoadingTimezones}
                      styles={{
                        ...customSelectStylesEditProfile,
                        control: (styles, state) => ({
                          ...styles,
                          maxHeight: '46px',
                          padding: '0 10px',
                          borderRadius: '5px',
                          background: state.isDisabled ? '#F9F9F9' : 'white',
                          border:
                            meta.submitError && meta.submitFailed
                              ? '1px solid #FF6666'
                              : '1px solid #C2BFBC',
                          boxShadow: state.isFocused
                            ? '0 0 0 3px #3366FF'
                            : 'none',
                          cursor: 'text',
                          '&:hover': {
                            borderColor: '#C2BFBC',
                          },
                        }),
                      }}
                      components={{
                        SingleValue: componentProps =>
                          SingleValueOption(componentProps),
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                        LoadingIndicator: () => LoadingIndicatorComponent(),
                        NoOptionsMessage: () => NoOptionsMessageComponent(),
                      }}
                      onMenuOpen={triggerScrollToBottom ?? noop}
                      {...input}
                    />
                    <FormError absolute="true" meta={meta} />
                  </div>
                </div>
              )}
            </Field>
            {(isAdmin || isDeazyLead) && team === 3 ? null : (
              <Field name="company">
                {({ input, meta }) => (
                  <div className="w-full relative">
                    <div className="mb-10">
                      <label
                        htmlFor="company-input"
                        className="inline-block mb-[5px] leading-[19px] font-semibold text-custom-primary-600"
                      >
                        Company
                      </label>
                      <AsyncSelect
                        id="company-input"
                        inputId="company-input"
                        classNamePrefix="react-select-prefix"
                        isBordered
                        cacheOptions
                        isClearable={false}
                        loadOptions={loadOptions}
                        backspaceRemovesValue={false}
                        defaultOptions={defaultOptions}
                        placeholder="Type/Select..."
                        isLoading={isLoadingData}
                        isDisabled={
                          isLoading ||
                          isLoadingData ||
                          isTeamAdminClientLeadOnly
                        }
                        styles={{
                          ...customSelectStylesEditProfile,
                          control: (styles, state) => ({
                            ...styles,
                            minHeight: '46px',
                            padding: '0 10px',
                            borderRadius: '5px',
                            background: state.isDisabled ? '#F9F9F9' : 'white',
                            border:
                              meta.submitError && meta.submitFailed
                                ? '1px solid #FF6666'
                                : '1px solid #C2BFBC',
                            boxShadow: state.isFocused
                              ? '0 0 0 3px #3366FF'
                              : 'none',
                            cursor: 'text',
                            '&:hover': {
                              borderColor: '#C2BFBC',
                            },
                          }),
                        }}
                        components={{
                          SingleValue: componentProps =>
                            SingleValueCompany(componentProps),
                          ClearIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => LoadingIndicatorComponent(),
                          NoOptionsMessage: () => NoOptionsMessageComponent(),
                        }}
                        onMenuOpen={triggerScrollToBottom ?? noop}
                        {...input}
                      />
                      <FormError absolute="true" meta={meta} />
                    </div>
                  </div>
                )}
              </Field>
            )}
            <div className="w-full flex flex-col">
              <Solid
                className="w-full"
                onClick={handleSubmit}
                disabled={isLoading}
                isLoading={isLoading}
                loadingText="Submitting..."
              >
                Create User and Send Invite
              </Solid>
              <Outlined
                className="mt-2 w-full"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Outlined>
            </div>
          </>
        );
      }}
    </Form>
  );
}

DetailsComponent.defaultProps = {
  onClose: undefined,
  team: undefined,
  permissions: undefined,
  triggerScrollToBottom: undefined,
};
DetailsComponent.propTypes = {
  onClose: PropTypes.func,
  team: PropTypes.number,
  permissions: PropTypes.object,
  triggerScrollToBottom: PropTypes.func,
};

export default inject('userStore')(observer(DetailsComponent));
