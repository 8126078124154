import React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../../utils';
import Button from '../Button';

const buttonColors = {
  primary:
    'bg-white rounded-lg px-6 py-2.5 font-work-sans leading-4 text-custom-primary-400 hover:text-custom-primary-300 active:text-custom-primary-500 disabled:bg-white disabled:text-custom-gray-200 h-auto',
  secondary:
    'bg-white rounded-lg px-6 py-2.5 font-work-sans leading-4 text-custom-orange-solid hover:text-custom-orange-mid active:text-custom-orange-dark disabled:bg-white disabled:text-custom-gray-200 h-auto',
  warning:
    'bg-white rounded-lg px-6 py-2.5 font-work-sans leading-4 text-custom-red-solid hover:text-custom-red-mid active:text-custom-red-dark disabled:bg-white disabled:text-custom-gray-200 h-auto',
};

/**
 * Ghost Button component
 * @param {String} className
 * @param {String} variant String of the color variant:
 * ```primary``` | ```secondary``` | ```warning```. Defaults to ```primary```.
 */
export default function Ghost({ className, variant = 'primary', ...props }) {
  const memoButtonColors = React.useMemo(() => buttonColors[variant], [
    variant,
  ]);

  return (
    <Button
      type="ghost"
      variant={variant}
      className={
        className
          ? joinClassNames(className, memoButtonColors)
          : memoButtonColors
      }
      {...props}
    />
  );
}

Ghost.defaultProps = {
  className: undefined,
  variant: 'primary',
  leftIcon: undefined,
  leftIconProps: undefined,
  leftIconClassName: undefined,
  iconButton: undefined,
  iconButtonProps: undefined,
  iconButtonClassName: undefined,
  children: undefined,
  disabled: undefined,
  type: 'ghost',
  isLoading: undefined,
  loadingText: undefined,
  loadingTextProps: undefined,
  loadingTextClassName: undefined,
};

Ghost.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  leftIconClassName: PropTypes.string,
  iconButton: PropTypes.string,
  iconButtonProps: PropTypes.object,
  iconButtonClassName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingTextProps: PropTypes.object,
  loadingTextClassName: PropTypes.string,
};
