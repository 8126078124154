import * as React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../utils';

export default function ErrorText({ children, ...props }) {
  const absoluteStyles =
    props.absolute === 'true'
      ? 'absolute bottom-[18px]'
      : 'mt-2 mb-2.5 leading-[19px]';
  return (
    <p
      className={
        props.className
          ? joinClassNames(
              `text-red-500 text-sm`,
              absoluteStyles,
              props.className,
            )
          : joinClassNames(`text-red-500 text-sm`, absoluteStyles)
      }
      {...props}
    >
      {children}
    </p>
  );
}

ErrorText.propTypes = { children: PropTypes.node.isRequired };
