import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

export default function AuthLayout({ isAuthenticated, user }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) navigate('/account/login');
    else {
      const { accessToken } = user || {};
      const { REACT_APP_PLATFORM_FRONT_URL } = process.env || {};

      if (!isEmpty(accessToken) && !isEmpty(REACT_APP_PLATFORM_FRONT_URL))
        window.location?.replace(
          `${REACT_APP_PLATFORM_FRONT_URL}/?token=${accessToken}`,
        );
    }
  }, [isAuthenticated]);
}
