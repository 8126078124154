import { RouterStore as MobxRouterStore } from 'mobx-react-router';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

class RouterStore extends MobxRouterStore {
  watchPrevLocation = () => {
    this.pageMeta = this.preparePageMeta(this.history.location);
    this.history.listen(location => {
      this.pageMeta = this.preparePageMeta(location);
    });
  };

  pageMeta = {
    fullHistory: [],
  };

  preparePageMeta = location => {
    const prevPageYOffset = window.pageYOffset;
    const fullHistory = [...this.pageMeta.fullHistory];

    if (fullHistory[0]) {
      fullHistory[0].pageYOffset = prevPageYOffset;
    }
    return {
      fullHistory: [
        {
          search: location.search,
          pathname: location.pathname,
        },
        ...fullHistory,
      ],
    };
  };

  prepareUrl = pathToSearch => {
    const fullHistory = [...this.pageMeta.fullHistory];
    if (!isEmpty(fullHistory)) {
      fullHistory.shift();
    }
    const [currentItem = {}, prevItem = {}] = this.pageMeta.fullHistory;
    const history = find(fullHistory, h => h.pathname === pathToSearch) || {};

    if (history?.pathname) {
      return {
        url: `${history.pathname}${history.search || ''}`,
        pageYOffset:
          prevItem.pathname && currentItem.pathname === prevItem.pathname
            ? 0
            : history.pageYOffset,
      };
    }
    return { url: pathToSearch, pageYOffset: 0 };
  };
}

export default new RouterStore();
