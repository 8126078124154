import React from 'react';
import { Helmet } from 'react-helmet';
import omit from 'lodash/omit';
import { IS_DEV, IS_STAGING, IS_PREPROD } from '../../app/constants';

export default class RouteWrapper extends React.Component {
  getDecoratedTitle = title => {
    if (IS_STAGING) {
      return `STAGING | ${title}`;
    }
    if (IS_PREPROD) {
      return `PREPROD | ${title}`;
    }
    if (IS_DEV) {
      return `LOCAL | ${title}`;
    }
    return title;
  };

  render() {
    const {
      headTitle = 'User Orchestration',
      component: Component,
      ...other
    } = this.props;

    const componentProps = omit(other, ['headTitle', 'element', 'path']);
    return (
      <>
        <Helmet defer={false}>
          <title>{this.getDecoratedTitle(headTitle)}</title>
        </Helmet>
        <Component {...componentProps} />
      </>
    );
  }
}
