import React from 'react';
import { observer } from 'mobx-react';
import { Routes, Route, useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import map from 'lodash/map';
import omit from 'lodash/omit';
import routes from './app/routesConfig';
import CreateUserModal from './pages/CreateUser/CreateUserModal';

smoothscroll.polyfill();

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <>
      <Routes location={background || location}>
        {map(routes, (route, idx) => {
          const { element: Element } = route;
          return (
            <Route
              caseSensitive
              key={`route-${idx}`}
              element={<Element />}
              {...omit(route, ['element'])}
            />
          );
        })}
      </Routes>
      {background && (
        <Routes key={`background-${location.pathname}`}>
          <Route path="create-user" element={<CreateUserModal />} />
        </Routes>
      )}
    </>
  );
}

export default observer(App);
