import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import StepComponent from '../StepComponent';
import DetailsComponent from './DetailsComponent';
import UserCreatedScreen from '../UserCreatedScreen/UserCreatedScreen';

function DetailsStep({
  steps,
  navigate,
  onClose,
  team,
  permissions,
  triggerScrollToBottom,
  userStore: { user },
}) {
  return !isEmpty(user) ? (
    <UserCreatedScreen
      user={user}
      onClose={onClose}
      permissions={permissions}
    />
  ) : (
    <>
      <p className="px-[38px] font-poppins font-semibold text-center text-[26px] leading-[39px]">
        Provide user details
      </p>
      {!isEmpty(steps) && (
        <div className="my-[30px] flex flex-row items-center justify-center flex-wrap sm:flex-nowrap">
          <StepComponent steps={steps} activeStep={3} navigate={navigate} />
        </div>
      )}
      <div
        className={`flex flex-col items-center justify-center ${
          isEmpty(steps) ? 'mt-[38px]' : ''
        }`}
      >
        <DetailsComponent
          onClose={onClose}
          team={team}
          permissions={permissions}
          triggerScrollToBottom={triggerScrollToBottom}
        />
      </div>
    </>
  );
}

DetailsStep.defaultProps = {
  steps: undefined,
  navigate: undefined,
  onClose: undefined,
  team: undefined,
  permissions: undefined,
  triggerScrollToBottom: undefined,
};
DetailsStep.propTypes = {
  steps: PropTypes.array,
  navigate: PropTypes.func,
  onClose: PropTypes.func,
  team: PropTypes.number,
  permissions: PropTypes.object,
  triggerScrollToBottom: PropTypes.func,
};

export default inject('userStore')(observer(DetailsStep));
