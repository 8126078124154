import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import toast, {
  Toaster as ToasterComponent,
  ToastIcon,
  resolveValue,
} from 'react-hot-toast';
import { joinClassNames } from '../../utils';
import Icon from '../Icon/Icon';

export default function Toaster({ className, ...props }) {
  return (
    <ToasterComponent gutter={30}>
      {t => {
        const backgroundColorDefault =
          t.type === 'error' ? 'bg-custom-red-light' : 'bg-white';
        const backgroundColor =
          t.type === 'success'
            ? 'bg-custom-green-light'
            : backgroundColorDefault;
        const borderColorDefault =
          t.type === 'error' ? 'border-custom-red-solid' : 'border-white';
        const borderColor =
          t.type === 'success'
            ? 'border-custom-green-solid'
            : borderColorDefault;
        const defaultPrimaryIconColor =
          t.type === 'error' ? '#FF6666' : 'black';
        const toastProps = {
          ...t,
          iconTheme: {
            primary: t.type === 'success' ? '#7CCC33' : defaultPrimaryIconColor,
          },
        };

        return (
          <AnimatePresence>
            {t.visible && (
              <motion.div
                key="toast"
                layout
                initial={{ opacity: 0, y: -50, scale: 0.3 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 0, scale: 0.5 }}
                className={
                  className
                    ? joinClassNames(
                        `flex items-center justify-between rounded-lg p-[18px] ${backgroundColor} border ${borderColor}`,
                        className,
                      )
                    : `flex items-center justify-between rounded-lg p-[18px] ${backgroundColor} border ${borderColor}`
                }
                {...props}
              >
                <ToastIcon toast={toastProps} />
                <p className="px-3">{resolveValue(t.message)}</p>
                <button type="button" onClick={() => toast.dismiss(t.id)}>
                  <Icon name="close" className="w-4 h-4 -mt-[1px]" />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        );
      }}
    </ToasterComponent>
  );
}
