import React from 'react';
import RouteWrapper from '../components/app/RouteWrapper';
import Login from '../pages/Login/Login';
import NotFound from '../pages/NotFound/NotFound';

export default [
  {
    path: '/',
    element: props => <RouteWrapper component={Login} {...props} />,
  },
  {
    path: '*',
    headTitle: 'Page Not Found',
    element: props => <RouteWrapper component={NotFound} {...props} />,
  },
];
