import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

function Backdrop({ children, className, handleClose }) {
  return (
    <motion.div
      className={`z-50 fixed inset-0 bg-[rgba(0,0,0,0.4)]
      flex items-center justify-center
      bg-backdrop backdrop-filter backdrop-blur-sm ${className}`}
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

function ModalContent({ id, children, className, ariaLabel }) {
  return (
    <motion.div
      id={id ?? null}
      tabIndex={-1}
      role="dialog"
      aria-modal
      aria-label={ariaLabel}
      className={`w-full max-h-[90vh] selection:relative overflow-auto py-10 px-14 bg-white shadow-modal ${className}`}
      variants={{
        hidden: {
          y: '2vh',
          opacity: 0,
        },
        visible: {
          y: '0',
          opacity: 1,
          transition: {
            type: 'tween',
            ease: 'easeIn',
            duration: 0.15,
          },
        },
        exit: {
          y: '0',
          opacity: 0,
          transition: {
            type: 'tween',
            ease: 'easeOut',
            duration: 0.1,
          },
        },
      }}
      initial="hidden"
      animate="visible"
      exit="exit"
      onClick={event => event.stopPropagation()}
    >
      {children}
    </motion.div>
  );
}

export default function Modal({
  children,
  className,
  isOpen,
  handleClose,
  backdropDismiss = true,
  backdropClassName,
  onExitComplete,
  ariaLabel,
  ...props
}) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [trigger, setTrigger] = onExitComplete ?? [undefined, undefined];

  useEffect(() => setIsBrowser(true), []);

  useEffect(() => {
    if (!isOpen) return;

    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const onExit = () =>
    setTrigger && trigger === 'fired' && setTrigger('completed');

  const handleKeyDown = event => {
    if (!isOpen || event.key !== 'Escape') return;

    handleClose();
  };

  if (!isBrowser) return null;

  const portal = createPortal(
    <AnimatePresence mode="wait" initial={false} onExitComplete={onExit}>
      {isOpen && (
        <Backdrop
          handleClose={backdropDismiss ? handleClose : undefined}
          className={backdropClassName}
        >
          <FocusLock className="w-full h-full flex justify-center items-center">
            <ModalContent
              id={props.id}
              className={className}
              handleClose={handleClose}
              ariaLabel={ariaLabel}
            >
              {children}
            </ModalContent>
          </FocusLock>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.getElementById('root'),
  );

  return portal;
}

Backdrop.defaultProps = {
  handleClose: undefined,
};

Backdrop.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ModalContent.defaultProps = {
  className: undefined,
  ariaLabel: undefined,
};

ModalContent.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  className: undefined,
  handleClose: undefined,
  backdropDismiss: true,
  backdropClassName: undefined,
  onExitComplete: undefined,
  ariaLabel: undefined,
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  backdropDismiss: PropTypes.bool,
  backdropClassName: PropTypes.string,
  onExitComplete: PropTypes.func,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
