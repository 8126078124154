import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';
import Modal from '../../components/Modal/Modal';
import Step from './Step';

const steps = [
  {
    number: 1,
    name: 'Team',
  },
  {
    number: 2,
    name: 'Permissions',
  },
  {
    number: 3,
    name: 'Details',
  },
];

export default function CreateUserModal() {
  const { user } = useAuth();
  const authenticatedUserRoles = user?.roles?.map(role => role?.name);
  const isAdmin = authenticatedUserRoles?.includes('Admin');
  const isDeazyLead = authenticatedUserRoles?.includes('Deazy Lead');
  const isClientLead = authenticatedUserRoles?.includes('Client Lead');
  const modalStepBasedOnRole = isAdmin || isDeazyLead ? 1 : 2;

  const navigate = useNavigate();
  const [team, setTeam] = useState(0);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [currentStep, setCurrentStep] = useState(modalStepBasedOnRole);
  const [permissions, setPermissions] = useState({
    active: true,
    accessible: true,
  });

  useEffect(() => {
    if (
      (team === 0 || team === 2 || team === 3) &&
      (isAdmin || isDeazyLead || isClientLead)
    ) {
      changePermissions('active', false);
    } else {
      changePermissions('active', true);
    }
  }, [team]);

  useEffect(() => {
    const filtersScrollContainer = document.getElementById('create-user-modal');
    if (scrollToBottom) {
      filtersScrollContainer.scrollTo({
        top: filtersScrollContainer.scrollHeight,
        behavior: 'smooth',
      });
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const goToStep = step => setCurrentStep(step);
  const changeTeam = step => setTeam(step);
  const changePermissions = (key, checked) =>
    setPermissions({ ...permissions, [key]: checked });
  const triggerScrollToBottom = useCallback(() => setScrollToBottom(true), [
    scrollToBottom,
  ]);

  return (
    <Modal
      isOpen
      id="create-user-modal"
      backdropDismiss={false}
      handleClose={() => navigate(-2)}
      className="max-w-[640px] rounded-lg !p-[30px] sm:!p-[50px]"
    >
      <Step
        steps={steps}
        currentStep={currentStep}
        navigate={goToStep}
        onClose={() => navigate(-2)}
        team={team}
        changeTeam={changeTeam}
        permissions={permissions}
        changePermissions={changePermissions}
        triggerScrollToBottom={triggerScrollToBottom}
      />
    </Modal>
  );
}
