import * as React from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../utils';
import { customIcons } from './Icons';

const fallbackIcon = {
  path: (
    <g stroke="currentColor" strokeWidth="1.5">
      <path
        strokeLinecap="round"
        fill="none"
        d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      />
      <path
        fill="currentColor"
        strokeLinecap="round"
        d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      />
      <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
    </g>
  ),
  viewBox: '0 0 24 24',
};

export function CustomIcon(props) {
  const { children, className, viewBox, ...rest } = props;

  return (
    <svg
      viewBox={viewBox ?? fallbackIcon.viewBox}
      className={
        className
          ? joinClassNames(
              'h-4 w-4 shrink-0 inline-block leading-none text-current align-middle',
              className,
            )
          : className
      }
      {...rest}
    >
      {children ?? fallbackIcon.path}
    </svg>
  );
}

const Icons = name => {
  const arrayOfObjects = Object.keys(customIcons).map(key => {
    const array = customIcons[key];
    array.name = key;

    return customIcons[key];
  });

  return find(arrayOfObjects, obj => obj.name === name);
};

/**
 * Primary UI component for Icon
 * Icon names can be found in Icon/Icons.js as object keys.
 */
function Icon({ name = Object.keys(customIcons)[1], ...props }) {
  const obj = Icons(name) || {};
  const { path, viewBox } = obj || {};

  if (!isEmpty(obj)) {
    return (
      <CustomIcon viewBox={viewBox} {...props}>
        {path}
      </CustomIcon>
    );
  }

  return <svg viewBox={fallbackIcon.viewBox}>{fallbackIcon.path}</svg>;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
