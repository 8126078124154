import React from 'react';
import { useAuth } from '@frontegg/react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import StepComponent from '../StepComponent';
import TeamTypeComponent from './TeamTypeComponent';
import Solid from '../../../components/Button/Solid/Solid';
import Outlined from '../../../components/Button/Outlined/Outlined';

const types = [
  {
    id: 1,
    name: 'Delivery Partner',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas magna erat.',
    image:
      'https://res.cloudinary.com/drrsqepgv/image/upload/v1666175436/Delivery_Partner_tem1xj.svg',
    imageAlt: 'Delivery Partner',
  },
  {
    id: 2,
    name: 'Client Team',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas magna erat.',
    image:
      'https://res.cloudinary.com/drrsqepgv/image/upload/v1666175436/Client_Team_lanxac.svg',
    imageAlt: 'Client Team',
  },
  {
    id: 3,
    name: 'Deazy',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas magna erat.',
    image:
      'https://res.cloudinary.com/drrsqepgv/image/upload/v1666175436/Deazy_sdixf1.svg',
    imageAlt: 'Deazy Team',
  },
];

export default function TeamStep({
  steps,
  navigate,
  onClose,
  team,
  changeTeam,
}) {
  const { user } = useAuth();
  const authenticatedUserRoles = user?.roles?.map(role => role?.name);
  const isAdmin = authenticatedUserRoles?.includes('Admin');
  const isDeazyLead = authenticatedUserRoles?.includes('Deazy Lead');
  const stepBasedOnRole =
    (isAdmin || isDeazyLead) && (team === 1 || team === 2) ? 2 : 3;

  return (
    <>
      <p className="px-[38px] font-poppins font-semibold text-center text-[26px] leading-[39px]">
        What type of team will the user belong to?
      </p>
      {!isEmpty(steps) && (
        <div className="my-[30px] flex flex-row items-center justify-center flex-wrap sm:flex-nowrap">
          <StepComponent steps={steps} activeStep={1} navigate={navigate} />
        </div>
      )}
      <div
        className={`flex flex-col items-center justify-center ${
          isEmpty(steps) ? 'mt-[38px]' : ''
        }`}
      >
        <TeamTypeComponent types={types} team={team} changeTeam={changeTeam} />
      </div>
      <div className="mt-[30px] flex flex-col">
        <Solid
          className="w-full"
          disabled={team === 0}
          onClick={() => navigate(stepBasedOnRole)}
        >
          Next Step
        </Solid>
        <Outlined className="mt-2 w-full" onClick={onClose}>
          Cancel
        </Outlined>
      </div>
    </>
  );
}

TeamStep.defaultProps = {
  steps: undefined,
  navigate: undefined,
  onClose: undefined,
  team: undefined,
  changeTeam: undefined,
};
TeamStep.propTypes = {
  steps: PropTypes.array,
  navigate: PropTypes.func,
  onClose: PropTypes.func,
  team: PropTypes.number,
  changeTeam: PropTypes.func,
};
