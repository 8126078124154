import React from 'react';
import PropTypes from 'prop-types';
import Solid from '../../../components/Button/Solid/Solid';
import Outlined from '../../../components/Button/Outlined/Outlined';

export default function UserCreatedButtons({ user, permissions, onClose }) {
  return (
    <div className="mt-10 flex flex-col">
      {!(!permissions.active && permissions.accessible) &&
        user?.staffProfile?.id && (
          <Solid
            className="w-full"
            as="a"
            href={`${process.env.REACT_APP_PLATFORM_FRONT_URL}/profile/${user?.staffProfile?.id}/edit`}
          >
            Edit User Profile
          </Solid>
        )}
      <Outlined className="mt-2 w-full" onClick={onClose}>
        Close
      </Outlined>
    </div>
  );
}

UserCreatedButtons.defaultProps = {
  user: undefined,
  onClose: undefined,
  permissions: undefined,
};

UserCreatedButtons.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  permissions: PropTypes.object,
};
