import * as React from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../utils';
import Spinner from '../Spinner/Spinner';
import Icon from '../Icon/Icon';

export default function Button({
  leftIcon,
  leftIconProps,
  leftIconClassName,
  iconButton,
  iconButtonProps,
  iconButtonClassName,
  className,
  children,
  disabled,
  type,
  as,
  variant,
  isLoading,
  loadingText,
  loadingTextProps,
  loadingTextClassName,
  ...props
}) {
  const getChildren = () => {
    if (isLoading) {
      return (
        <div className="flex flex-row flex-nowrap justify-center items-center">
          <Spinner
            type={type}
            variant={variant}
            className={`w-4 h-4 ${isEmpty(loadingText) ? '' : 'mr-2'}`}
            isLoading={isLoading}
            isDisabled={disabled}
          />
          {!isEmpty(loadingText) && (
            <span
              className={
                loadingTextClassName
                  ? joinClassNames('font-work-sans', loadingTextClassName)
                  : loadingTextClassName
              }
            >
              {loadingText}
            </span>
          )}
        </div>
      );
    }

    if (iconButton) {
      return (
        <Icon
          name={iconButton}
          className={
            iconButtonClassName
              ? joinClassNames('text-black', iconButtonClassName)
              : 'text-black'
          }
          {...iconButtonProps}
        />
      );
    }

    if (leftIcon) {
      return (
        <>
          <Icon
            name={leftIcon}
            className={
              leftIconClassName
                ? joinClassNames('mr-2 -mt-0.5 text-black', leftIconClassName)
                : 'mr-2 -mt-0.5 text-black'
            }
            {...leftIconProps}
          />
          {children}
        </>
      );
    }

    return children;
  };

  const isLoadingClickBlock = isLoading ? 'pointer-events-none' : '';
  const isLeftIconPadding = !isEmpty(leftIcon) ? 'pl-6 pr-6' : '';
  const isIconButtonPadding = !isEmpty(iconButton)
    ? '!px-4'
    : isLeftIconPadding;

  if (as === 'a') {
    const isDisabled = disabled ? 'bg-custom-gray-200 cursor-not-allowed' : '';
    return (
      <a
        className={
          className
            ? joinClassNames(
                `min-h-[48px] flex justify-center items-center whitespace-nowrap leading-none text-base placeholder:rounded-lg ${isDisabled}`,
                className,
                isIconButtonPadding,
                isLoadingClickBlock,
              )
            : `min-h-[48px] flex justify-center items-center leading-none text-black text-base rounded-lg ${isDisabled} ${isIconButtonPadding} ${isLoadingClickBlock}`
        }
        {...props}
      >
        {getChildren()}
      </a>
    );
  }

  if (as === 'link') {
    const isDisabled = disabled ? 'bg-custom-gray-200 cursor-not-allowed' : '';
    return (
      <Link
        className={
          className
            ? joinClassNames(
                `min-h-[48px] flex justify-center items-center whitespace-nowrap leading-none text-base placeholder:rounded-lg ${isDisabled}`,
                className,
                isIconButtonPadding,
                isLoadingClickBlock,
              )
            : `min-h-[48px] flex justify-center items-center leading-none text-black text-base rounded-lg ${isDisabled} ${isIconButtonPadding} ${isLoadingClickBlock}`
        }
        {...props}
      >
        {getChildren()}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={
        className
          ? joinClassNames(
              'min-h-[48px] leading-none text-base placeholder:rounded-lg disabled:bg-custom-gray-200 disabled:cursor-not-allowed',
              className,
              isIconButtonPadding,
              isLoadingClickBlock,
            )
          : `min-h-[48px] leading-none text-black text-base rounded-lg disabled:bg-custom-gray-200 disabled:cursor-not-allowed ${isIconButtonPadding} ${isLoadingClickBlock}`
      }
      disabled={disabled}
      {...props}
    >
      {getChildren()}
    </button>
  );
}

Button.defaultProps = {
  leftIcon: undefined,
  leftIconProps: undefined,
  leftIconClassName: undefined,
  iconButton: undefined,
  iconButtonProps: undefined,
  iconButtonClassName: undefined,
  className: undefined,
  children: undefined,
  disabled: undefined,
  type: undefined,
  as: undefined,
  variant: undefined,
  isLoading: undefined,
  loadingText: undefined,
  loadingTextProps: undefined,
  loadingTextClassName: undefined,
};

Button.propTypes = {
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  leftIconClassName: PropTypes.string,
  iconButton: PropTypes.string,
  iconButtonProps: PropTypes.object,
  iconButtonClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  as: PropTypes.string,
  variant: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingTextProps: PropTypes.object,
  loadingTextClassName: PropTypes.string,
};
