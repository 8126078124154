import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { IS_LOCALHOST } from '../../app/constants';
import { API, TOKEN_KEY } from '../../app/api';
import AuthLayout from './AuthLayout';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading, isAuthenticated } = useAuth();

  const token = Cookies.get(TOKEN_KEY);
  const isCreateUser = 'createUser' in qs.parse(location.search);

  const authenticatedUserRoles = user?.roles?.map(role => role?.name);
  const isTeamMemberOnly =
    user?.roles?.length === 1 &&
    authenticatedUserRoles?.includes('Team Member');

  if (token)
    API.apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  useEffect(() => {
    if (isCreateUser && isAuthenticated && !isTeamMemberOnly) {
      navigate('/create-user', { state: { background: location } });
    }

    if (isAuthenticated && !isLoading && !token) {
      Cookies.set(TOKEN_KEY, user?.accessToken, {
        domain: IS_LOCALHOST ? 'localhost' : 'deazy.com',
        sameSite: 'strict',
        secure: true,
      });

      API.apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, [isAuthenticated, isLoading]);

  return isCreateUser && isAuthenticated && !isTeamMemberOnly ? null : (
    <AuthLayout isAuthenticated={isAuthenticated} user={user} />
  );
}
