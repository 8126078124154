import { API } from '../app/api';
import { IS_DEV, IS_STAGING } from '../app/constants';
import routerStore from './routerStore';
import AppStore from './appStore';
import UserStore from './userStore';

class RootStore {
  constructor() {
    this.routerStore = routerStore;
    this.appStore = new AppStore({ API, ...this });
    this.userStore = new UserStore({ API, ...this });
    this.routerStore.watchPrevLocation();
  }
}

const rootStore = new RootStore();

if (IS_STAGING || IS_DEV) {
  window.rootStore = rootStore;
}

export default rootStore;
