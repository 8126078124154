import React from 'react';
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

function Image({
  src,
  height,
  width,
  scrollPosition,
  wrapperClassName,
  alt = 'Image',
  ...props
}) {
  return (
    <LazyLoadImage
      src={src}
      alt={alt}
      width={width}
      height={height}
      effect="opacity"
      draggable={false}
      scrollPosition={scrollPosition}
      wrapperClassName={wrapperClassName}
      {...omit(props, ['forwardRef'])}
    />
  );
}

Image.defaultProps = {
  ref: undefined,
  afterLoad: undefined,
  beforeLoad: undefined,
  delayMethod: 'throttle',
  delayTime: 300,
  placeholder: undefined,
  placeholderSrc: undefined,
  threshold: 100,
  useIntersectionObserver: true,
  visibleByDefault: false,
  wrapperClassName: undefined,
  wrapperProps: undefined,
  src: undefined,
  scrollPosition: undefined,
};

Image.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
  delayMethod: PropTypes.string,
  delayTime: PropTypes.number,
  placeholder: PropTypes.node,
  placeholderSrc: PropTypes.string,
  threshold: PropTypes.number,
  useIntersectionObserver: PropTypes.bool,
  visibleByDefault: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  wrapperProps: PropTypes.object,
  src: PropTypes.string,
  height: (PropTypes.string || PropTypes.number).isRequired,
  width: (PropTypes.string || PropTypes.number).isRequired,
  scrollPosition: PropTypes.object,
  alt: PropTypes.string.isRequired,
};

export default trackWindowScroll(Image);
