import React from 'react';
import PropTypes from 'prop-types';
import { joinClassNames } from '../../../utils';
import Button from '../Button';

const buttonColors = {
  primary:
    'text-white rounded-lg px-6 py-3 font-work-sans leading-4 bg-custom-primary-400 hover:bg-custom-primary-300 active:bg-custom-primary-500 disabled:bg-custom-gray-200',
  secondary:
    'text-white rounded-lg px-6 py-3 font-work-sans leading-4 bg-custom-orange-solid hover:bg-custom-orange-mid active:bg-custom-orange-dark',
  warning:
    'text-white rounded-lg px-6 py-3 font-work-sans leading-4 bg-custom-red-solid hover:bg-custom-red-mid active:bg-custom-red-dark',
};

/**
 * Solid Button component
 * @param {String} className
 * @param {String} variant String of the color variant:
 * ```primary``` | ```secondary``` | ```warning```. Defaults to ```primary```.
 */
export default function Solid({ className, variant = 'primary', ...props }) {
  const memoButtonColors = React.useMemo(() => buttonColors[variant], [
    variant,
  ]);

  return (
    <Button
      type="solid"
      variant={variant}
      className={
        className
          ? joinClassNames(memoButtonColors, className)
          : memoButtonColors
      }
      {...props}
    />
  );
}

Solid.defaultProps = {
  className: undefined,
  variant: 'primary',
  leftIcon: undefined,
  leftIconProps: undefined,
  leftIconClassName: undefined,
  iconButton: undefined,
  iconButtonProps: undefined,
  iconButtonClassName: undefined,
  children: undefined,
  disabled: undefined,
  type: 'solid',
  isLoading: undefined,
  loadingText: undefined,
  loadingTextProps: undefined,
  loadingTextClassName: undefined,
};

Solid.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  leftIconClassName: PropTypes.string,
  iconButton: PropTypes.string,
  iconButtonProps: PropTypes.object,
  iconButtonClassName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingTextProps: PropTypes.object,
  loadingTextClassName: PropTypes.string,
};
