import React from 'react';
import PropTypes from 'prop-types';
import Ghost from '../../../components/Button/Ghost/Ghost';

const TeamTypeComponent = ({ types, team, changeTeam }) =>
  types?.map(({ id, name, description, image, imageAlt }) => {
    const activeType = id === team;
    return (
      <Ghost
        key={id}
        onClick={() => changeTeam(id)}
        className={`w-full flex !p-5 mb-2.5 rounded-lg ${
          activeType
            ? 'ring-[3px] ring-custom-primary-400 focus:ring-custom-primary-400'
            : 'ring-1 ring-custom-gray-200'
        }`}
      >
        <img src={image} className="w-20 h-20 mr-5" alt={imageAlt} />
        <div className="flex flex-col justify-center items-start text-black">
          <p className="text-left font-poppins font-semibold text-lg">{name}</p>
          <span className="mt-2 text-left hidden sm:block">{description}</span>
        </div>
      </Ghost>
    );
  });

export default TeamTypeComponent;

TeamTypeComponent.defaultProps = {
  types: undefined,
  team: undefined,
  changeTeam: undefined,
};

TeamTypeComponent.propTypes = {
  types: PropTypes.array,
  team: PropTypes.number,
  changeTeam: PropTypes.func,
};
